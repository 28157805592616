<template>
    <error-view>
        <div id="page-container" class="main-content-boxed">
        <!-- Main Container -->
        <main id="main-container">
            <!-- Page Content -->
            <div class="hero bg-white">
                <div class="hero-inner">
                    <div class="content content-full">
                        <div class="py-30 text-center">
                            <div class="error-img">
                                <img src="~assets/img/errors/sad.svg" alt="">
                            </div>
                            <h1 class="h2 font-w700 mt-30 mb-10">Упс! Кажется что-то сломалось:(</h1>
                            <router-link class="error-link" to="/">
                                <img src="~assets/img/header_user/wikiworks-logo.svg" height="32" width="194" alt="Wikiworks">
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Page Content -->
        </main>
        <!-- END Main Container -->
        </div>
        <!-- END Page Container -->
    </error-view>
</template>

<script>
    import ErrorView from '@/views/errors/ErrorView'
    export default {
        name: "500",
        components: { ErrorView },
        async created () {
            if (this.$metrika) await this.$metrika.reachGoal('loadError404Page');
        }
    }
</script>

<style scoped>

</style>
